.keyboard {
    --margins: .3125em;//.375em;

    user-select: none;
    max-width: 43.5em;
    margin: 0 auto;
    background-color: rgba(0,0,0,.1);
    padding: .5em;
    border-radius: .25em;
    font-size: 1rem;

    transition: all .15s ease-in-out;

    @media (min-width: 992px) {
        font-size: 1.25rem;
    }

    @media (min-width: 1200px) {
        // font-size: 1.5rem;
    }

    @media (min-width: 1400px) {
        // font-size: 1.875rem;
        // font-size: 2rem;
    }
}

.keyboardRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &:not(:last-child) {
        margin-bottom: var(--margins);
    }
}

.keyboardKey {
    cursor: pointer;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 3px -1px rgb(0 0 0 / 30%);
    height: 2.5em;
    border-radius: .25em;
    box-sizing: border-box;
    padding: .25em;
    color: black;
    background: white;
    border-bottom: 1px solid #b5b5b5;

    span {
        font-size: 0.75em;
    }

    @media (min-width: 360px) {
        span {
            font-size: 1em;
        }
    }
    @media (min-width: 768px) {
        flex: 0 1 2.5em;
    }

    &:not(:last-child) {
        margin-right: var(--margins);
    }
    span {
        pointer-events: none;
    }
    &_auto {
        cursor: auto;
        display: none;
        flex: 1 0 auto;
        justify-content: flex-start;

        @media (min-width: 768px) {
            display: flex;
            padding: .25em .25em .25em .625em;
        }
    }
    &_wide {
        flex: 8 0 auto;
    }
    &_smaller {
        span {
            font-size: 0.75em;
        }
    }
    &_symbol {
        justify-content: center;
        padding: 0.25em;

        span {
            font-size: 0.75em;
        }
    }
}

[data-theme="dark"] {

    .keyboard {
        background-color: rgba(0, 0, 0, 0.4);
    }

    .keyboardKey {
        color: white;
        background: black;
        border-bottom: 1px solid #3a3a3a;
    }

}